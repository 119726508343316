import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import * as Icons from 'lucide-react';
import './index.css';
import SEO from './components/common/SEO';
import HelmetProvider from './providers/HelmetProvider';
import { 
  initGA, 
  logPageView, 
  logButtonClick, 
  logOutboundLink,
  logVideoInteraction,
  logFeatureCardInteraction,
  logScrollDepth,
  logTimeOnPage,
  GA_TRACKING_ID
} from './utils/analytics';
import CookieConsent from './components/common/CookieConsent';
import { GOOGLE_FORM_URL } from './api/waitlist';

// Import components
import Navigation from './components/common/Navigation';
import NorthernLights from './components/common/NorthernLights';
import FAQ from './FAQ';
import HowItWorks from './HowItWorks';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import AboutUs from './AboutUs';
import CookiePolicy from './CookiePolicy';

// Analytics wrapper component
const AnalyticsWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const startTime = useRef(Date.now());
  const initialized = useRef(false);

  // Initialize GA based on cookie consent
  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'accepted' && !initialized.current) {
      console.log('Initializing GA with ID:', GA_TRACKING_ID);
      initGA();
      initialized.current = true;
    }
  }, []);

  // Handle route changes
  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'accepted' && initialized.current) {
      logPageView(location.pathname);
      startTime.current = Date.now();

      return () => {
        const timeSpent = Math.floor((Date.now() - startTime.current) / 1000);
        logTimeOnPage(timeSpent);
      };
    }
  }, [location]);

  // Track scroll depth
  useEffect(() => {
    let ticking = false;
    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const scrolled = window.scrollY;
          const viewportHeight = window.innerHeight;
          const totalHeight = document.documentElement.scrollHeight;
          const scrollPercent = Math.floor((scrolled / (totalHeight - viewportHeight)) * 100);
          
          if (scrollPercent % 25 === 0) { // Log at 25%, 50%, 75%, 100%
            const consent = localStorage.getItem('cookieConsent');
            if (consent === 'accepted') {
              logScrollDepth(scrollPercent);
            }
          }
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return <>{children}</>;
};

const HomePage: React.FC = () => {
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
      const consent = localStorage.getItem('cookieConsent');
      if (consent === 'accepted') {
        logVideoInteraction(isMuted ? 'unmute' : 'mute', 'promo-video');
      }
    }
  };

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        const consent = localStorage.getItem('cookieConsent');
        if (consent === 'accepted') {
          logVideoInteraction('pause', 'promo-video');
        }
      } else {
        videoRef.current.play();
        const consent = localStorage.getItem('cookieConsent');
        if (consent === 'accepted') {
          logVideoInteraction('play', 'promo-video');
        }
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleWaitlistClick = () => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'accepted') {
      logButtonClick('Join Waitlist');
      logOutboundLink('Google Form');
    }
  };

  const handleFeatureHover = (featureTitle: string) => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'accepted') {
      logFeatureCardInteraction(featureTitle, 'hover');
    }
  };

  const handleFeatureClick = (featureTitle: string) => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'accepted') {
      logFeatureCardInteraction(featureTitle, 'click');
    }
  };

  const features = [
    {
      icon: <Icons.Brain className="w-12 h-12 text-eduvibe-green" />,
      title: "Supercharge Memory",
      description: "Master any subject with our innovative audio-based learning technology"
    },
    {
      icon: <Icons.Music className="w-12 h-12 text-eduvibe-green" />,
      title: "Learn Through Music",
      description: "Transform complex topics into memorable melodies that stick"
    },
    {
      icon: <Icons.TrendingUp className="w-12 h-12 text-eduvibe-green" />,
      title: "Track Success",
      description: "Watch your knowledge grow with personalized learning analytics"
    }
  ];

  return (
    <div className="min-h-screen bg-eduvibe-dark text-white overflow-x-hidden">
      <SEO />
      <NorthernLights />
      
      {/* Hero Section */}
      <header className="container mx-auto px-4 pt-8 pb-4 flex flex-col items-center relative z-20">
        <img 
          src="/images/logo.png" 
          alt="EduVibz Logo - Music Powered Learning Platform" 
          className="h-28 md:h-20 w-auto mb-4"
        />
        
        <div className="inline-block mb-4 px-4 py-1 bg-eduvibe-green/20 rounded-full text-eduvibe-green font-semibold">
          Launching Soon 🎵
        </div>
        
        <h1 className="text-4xl md:text-6xl font-extrabold mb-4 text-center">
          <span className="text-eduvibe-green">Edu</span>
          <span className="text-eduvibe-blue">Vibz</span>: Where Learning Meets Music
        </h1>
        
        <p className="text-xl md:text-2xl text-eduvibe-light mb-8 text-center max-w-2xl">
          Experience the future of learning with high quality musical vibz that follow the school syllabus. Master any subject through the power of melody.
        </p>
      </header>

      <main className="flex-grow container mx-auto px-4 relative z-10">
        {/* Video Section */}
        <div className="relative w-full max-w-4xl mx-auto mb-8" aria-label="Promotional Video">
          <div className="aspect-w-16 aspect-h-9 rounded-2xl overflow-hidden shadow-2xl ring-4 ring-eduvibe-blue ring-opacity-50">
            <video 
              ref={videoRef}
              className="w-full h-full object-cover transform hover:scale-105 transition duration-500"
              playsInline
              muted={isMuted}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              aria-label="EduVibz promotional video"
            >
              <source src="https://assets.eduvibz.com/LandingPageEduVibz.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            
            <div 
              className="absolute inset-0 cursor-pointer"
              onClick={togglePlay}
            >
              <div className="absolute inset-0 bg-gradient-to-t from-eduvibe-dark/30 to-transparent pointer-events-none"></div>
              
              <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                <div 
                  className={`transform transition-all duration-300 ${
                    isPlaying ? 'opacity-0 scale-95' : 'opacity-100 scale-100'
                  } bg-eduvibe-blue bg-opacity-70 p-4 rounded-full`}
                >
                  <Icons.Play className="w-8 h-8 text-white" aria-label="Play video" />
                </div>
              </div>
            </div>
          </div>

          <div className="absolute bottom-0 right-4">
            <button 
              onClick={(e) => {
                e.stopPropagation();
                toggleMute();
              }}
              className="bg-eduvibe-blue bg-opacity-70 p-2 rounded-full hover:bg-opacity-100 transition duration-300 z-10 w-10 h-10 flex items-center justify-center"
              aria-label={isMuted ? "Unmute video" : "Mute video"}
            >
              {isMuted ? 
                <Icons.VolumeX className="w-6 h-6 text-white" /> : 
                <Icons.Volume2 className="w-6 h-6 text-white" />
              }
            </button>
          </div>
        </div>

        {/* Waitlist Form */}
        <div id="join" className="form-container px-4 md:px-0 mb-24" aria-labelledby="form-heading">
          <div className="max-w-2xl mx-auto bg-eduvibe-dark/30 backdrop-blur-lg p-6 md:p-8 rounded-2xl border border-eduvibe-light/10 transform hover:scale-105 transition duration-300">
            <h2 id="form-heading" className="text-2xl md:text-3xl font-bold mb-2 text-center">Be First in Line! 🎵</h2>
            <p className="text-center text-eduvibe-light mb-6">Join our exclusive waitlist for early access and special launch benefits.</p>
            <div className="space-y-4">
              <a
                href={GOOGLE_FORM_URL}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleWaitlistClick}
                className="w-full submit-button bg-gradient-to-r from-eduvibe-green to-eduvibe-blue text-eduvibe-dark font-bold py-4 px-6 rounded-lg hover:opacity-90 transition-all duration-300 flex items-center justify-center text-lg"
              >
                <Icons.Music className="w-6 h-6 mr-2" aria-hidden="true" />
                Join Waitlist
              </a>
            </div>
          </div>
        </div>

        {/* Features */}
        <section className="grid md:grid-cols-3 gap-8 mb-24 pt-16" aria-labelledby="features-heading">
          <h2 id="features-heading" className="sr-only">Key Features</h2>
          {features.map((feature, index) => (
            <div 
              key={index} 
              className="bg-eduvibe-blue bg-opacity-50 p-6 rounded-xl backdrop-filter backdrop-blur-lg text-center transform hover:scale-105 transition duration-300"
              onMouseEnter={() => handleFeatureHover(feature.title)}
              onClick={() => handleFeatureClick(feature.title)}
            >
              <div aria-hidden="true">{feature.icon}</div>
              <h3 className="text-2xl font-bold mb-2">{feature.title}</h3>
              <p className="text-white">{feature.description}</p>
            </div>
          ))}
        </section>

        {/* Timeline */}
        <section className="py-16" aria-labelledby="timeline-heading">
          <h2 id="timeline-heading" className="text-3xl md:text-4xl font-bold text-center mb-12">
            Your Journey Starts Here
          </h2>
          <div className="timeline-container">
            {/* Step 1 */}
            <div className="timeline-step">
              <div className="timeline-dot">
                <div className="w-8 h-8 bg-eduvibe-green rounded-full flex items-center justify-center">
                  <span className="text-eduvibe-dark font-bold">1</span>
                </div>
              </div>
              <div className="timeline-content left">
                <h3 className="text-xl font-bold mb-2">Join the Waitlist</h3>
                <p className="text-eduvibe-light">
                  Be among the first to experience the future of music education.
                </p>
              </div>
            </div>

            {/* Step 2 */}
            <div className="timeline-step">
              <div className="timeline-dot">
                <div className="w-8 h-8 bg-eduvibe-blue rounded-full flex items-center justify-center">
                  <span className="text-eduvibe-dark font-bold">2</span>
                </div>
              </div>
              <div className="timeline-content right">
                <h3 className="text-xl font-bold mb-2">Get Early Access</h3>
                <p className="text-eduvibe-light">
                  Receive exclusive access to our beta platform and special features.
                </p>
              </div>
            </div>

            {/* Step 3 */}
            <div className="timeline-step">
              <div className="timeline-dot">
                <div className="w-8 h-8 bg-eduvibe-green rounded-full flex items-center justify-center">
                  <span className="text-eduvibe-dark font-bold">3</span>
                </div>
              </div>
              <div className="timeline-content left">
                <h3 className="text-xl font-bold mb-2">Start Learning</h3>
                <p className="text-eduvibe-light">
                  Begin your musical journey with personalized learning paths.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Final CTA */}
        <section className="text-center mb-16" aria-labelledby="cta-heading">
          <div className="max-w-2xl mx-auto">
            <div className="inline-block mb-4 px-4 py-1 bg-eduvibe-blue/20 rounded-full text-eduvibe-blue font-semibold">
              Limited Time Offer 🚀
            </div>
            <h2 id="cta-heading" className="text-3xl font-bold mb-4">The Future of Learning Awaits</h2>
            <p className="text-eduvibe-light mb-6">Early birds get exclusive early access and updates. Don't miss out!</p>
            <a 
              href={GOOGLE_FORM_URL}
              target="_blank"
              rel="noopener noreferrer" 
              className="bg-eduvibe-green text-eduvibe-dark px-8 py-3 rounded-full text-lg font-bold hover:bg-eduvibe-blue transition duration-300 inline-flex items-center"
            >
              <Icons.Music className="w-5 h-5 mr-2" aria-hidden="true" />
              Join Waitlist
            </a>
          </div>
        </section>
      </main>

      <footer className="border-t border-white/10">
        <div className="container mx-auto px-4 py-8">
          <div className="text-center">
            <p className="text-eduvibe-light"> 2024 EduVibz. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

const App: React.FC = () => {
  const handleAcceptCookies = () => {
    initGA();
  };

  const handleDeclineCookies = () => {
    // Remove any existing analytics scripts/cookies
    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].src.includes('googletagmanager')) {
        scripts[i].remove();
        break;
      }
    }
  };

  return (
    <HelmetProvider>
      <Router>
        <AnalyticsWrapper>
          <div className="min-h-screen bg-eduvibe-dark text-white">
            <Navigation />
            <div className="relative z-10">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/how-it-works" element={<HowItWorks />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/cookie-policy" element={<CookiePolicy />} />
              </Routes>
            </div>
            <CookieConsent
              onAccept={handleAcceptCookies}
              onDecline={handleDeclineCookies}
            />
          </div>
        </AnalyticsWrapper>
      </Router>
    </HelmetProvider>
  );
};

export default App;
