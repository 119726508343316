import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO: React.FC = () => {
  return (
    <Helmet>
      <title>EduVibz - Learn to the Beat | AI-Powered Music Learning Platform</title>
      <meta name="description" content="Transform your learning experience with EduVibz. Our AI-powered platform combines education with music to enhance memory retention and make learning more enjoyable." />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://eduvibz.com/" />
      <meta property="og:title" content="EduVibz - Learn to the Beat" />
      <meta property="og:description" content="Transform your learning experience with AI-powered music learning." />
      <meta property="og:image" content="/og-image.jpg" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://eduvibz.com/" />
      <meta property="twitter:title" content="EduVibz - Learn to the Beat" />
      <meta property="twitter:description" content="Transform your learning experience with AI-powered music learning." />
      <meta property="twitter:image" content="/og-image.jpg" />

      {/* Schema.org markup */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "EduVibz",
          "description": "Transform your learning experience with AI-powered music learning.",
          "url": "https://eduvibz.com",
          "potentialAction": {
            "@type": "JoinAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://eduvibz.com/#join"
            },
            "result": {
              "@type": "JoinActionResult",
              "name": "Join Waitlist"
            }
          }
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
