import React from 'react';
import NorthernLights from './NorthernLights';

interface LayoutProps {
  children: React.ReactNode;
  showNorthernLights?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, showNorthernLights = true }) => {
  return (
    <div className="min-h-screen bg-eduvibe-dark text-white relative">
      {showNorthernLights && <NorthernLights />}
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
};

export default Layout;
