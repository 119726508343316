import React from 'react';
import { motion, Variants } from 'framer-motion';
import { Shield, Lock, Eye, FileText } from 'lucide-react';
import Layout from './components/common/Layout';
import PageHeader from './components/common/PageHeader';

const fadeIn: Variants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut",
    },
  },
};

const PrivacyPolicy: React.FC = () => {
  const sections = [
    {
      icon: <Shield className="w-12 h-12 text-eduvibe-green" />,
      title: "Data Protection",
      content: "We take the protection of your personal data seriously. All information collected is encrypted and stored securely using industry-standard protocols."
    },
    {
      icon: <Lock className="w-12 h-12 text-eduvibe-green" />,
      title: "Information Security",
      content: "Your data is protected by advanced security measures including encryption, secure socket layer technology (SSL), and regular security audits."
    },
    {
      icon: <Eye className="w-12 h-12 text-eduvibe-green" />,
      title: "Data Usage",
      content: "We only collect and use information that is necessary to provide and improve our services. Your data is never sold to third parties."
    },
    {
      icon: <FileText className="w-12 h-12 text-eduvibe-green" />,
      title: "Your Rights",
      content: "You have the right to access, modify, or delete your personal information at any time. Contact our support team for assistance."
    }
  ];

  return (
    <Layout showNorthernLights={true}>
      <PageHeader />
      <div className="container mx-auto px-4 py-8">
        <motion.section
          className="max-w-4xl mx-auto mb-16 text-center"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6 text-eduvibe-green">Privacy Policy</h2>
          <p className="text-xl leading-relaxed mb-8">
            Your privacy is our top priority. We are committed to protecting your personal information
            and ensuring a secure experience on our platform.
          </p>
        </motion.section>

        <motion.section
          className="max-w-6xl mx-auto mb-16"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {sections.map((section, index) => (
              <motion.div
                key={section.title}
                className="bg-eduvibe-blue bg-opacity-50 p-6 rounded-xl backdrop-filter backdrop-blur-lg transform hover:scale-105 transition duration-300"
                initial="hidden"
                animate="visible"
                variants={fadeIn}
                transition={{ delay: index * 0.1 }}
                role="article"
              >
                <div className="flex items-center mb-4">
                  <div aria-hidden="true">{section.icon}</div>
                  <h3 className="text-2xl font-bold ml-4">{section.title}</h3>
                </div>
                <p className="text-white">{section.content}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section
          className="max-w-4xl mx-auto prose prose-invert"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <div className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-xl backdrop-filter backdrop-blur-lg">
            <h3 className="text-2xl font-bold mb-4 text-eduvibe-green">Detailed Privacy Information</h3>
            <p className="mb-4">
              This Privacy Policy describes how EduVibz ("we," "our," or "us") collects, uses, and shares your personal information when you use our services.
            </p>
            <h4 className="text-xl font-bold mb-2 text-eduvibe-green">Information We Collect</h4>
            <ul className="list-disc pl-6 mb-4">
              <li>Email</li>
              <li>Usage data and analytics</li>
              <li>Device and browser information</li>
              <li>Cookies and similar technologies</li>
            </ul>
            <h4 className="text-xl font-bold mb-2 text-eduvibe-green">How We Use Your Information</h4>
            <ul className="list-disc pl-6 mb-4">
              <li>Provide and improve our services</li>
              <li>Communicate with you about updates</li>
              <li>Analyze and optimize user experience</li>
              <li>Ensure platform security</li>
            </ul>
            <p className="text-sm mt-8">
              For privacy-related inquiries, please contact us at privacy@eduvibz.com
              <br />
              Last updated: November 2024
            </p>
          </div>
        </motion.section>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;