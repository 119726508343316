import React from 'react';
import { Link } from 'react-router-dom';

const PageHeader: React.FC = () => {
  return (
    <header className="container mx-auto px-4 py-8 flex justify-center items-center relative z-20" role="banner">
      <Link to="/">
        <img 
          src="/images/logo.png" 
          alt="EduVibz Logo - Music Powered Learning Platform" 
          className="h-28 md:h-20 w-auto"
        />
      </Link>
    </header>
  );
};

export default PageHeader;
