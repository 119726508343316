import React from 'react';
import { motion, Variants } from 'framer-motion';
import { Scale, UserCheck, AlertCircle, Bookmark } from 'lucide-react';
import Layout from './components/common/Layout';
import PageHeader from './components/common/PageHeader';

const fadeIn: Variants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut",
    },
  },
};

const TermsOfService: React.FC = () => {
  const sections = [
    {
      icon: <Scale className="w-12 h-12 text-eduvibe-green" />,
      title: "Agreement Terms",
      content: "By accessing our platform, you agree to be bound by these terms and all applicable laws and regulations."
    },
    {
      icon: <UserCheck className="w-12 h-12 text-eduvibe-green" />,
      title: "User Responsibilities",
      content: "Users are responsible for maintaining account security and complying with our community guidelines."
    },
    {
      icon: <AlertCircle className="w-12 h-12 text-eduvibe-green" />,
      title: "Platform Rules",
      content: "We maintain strict guidelines to ensure a safe and productive learning environment for all users."
    },
    {
      icon: <Bookmark className="w-12 h-12 text-eduvibe-green" />,
      title: "Content Usage",
      content: "All content on the platform is protected by copyright and may only be used for personal educational purposes."
    }
  ];

  return (
    <Layout showNorthernLights={true}>
      <PageHeader />
      <div className="container mx-auto px-4 py-8">
        <motion.section
          className="max-w-4xl mx-auto mb-16 text-center"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6 text-eduvibe-green">Terms of Service</h2>
          <p className="text-xl leading-relaxed mb-8">
            Please read these terms carefully before using our platform. These terms outline your rights
            and responsibilities as a user of EduVibz.
          </p>
        </motion.section>

        <motion.section
          className="max-w-6xl mx-auto mb-16"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {sections.map((section, index) => (
              <motion.div
                key={section.title}
                className="bg-eduvibe-blue bg-opacity-50 p-6 rounded-xl backdrop-filter backdrop-blur-lg transform hover:scale-105 transition duration-300"
                initial="hidden"
                animate="visible"
                variants={fadeIn}
                transition={{ delay: index * 0.1 }}
                role="article"
              >
                <div className="flex items-center mb-4">
                  <div aria-hidden="true">{section.icon}</div>
                  <h3 className="text-2xl font-bold ml-4">{section.title}</h3>
                </div>
                <p className="text-white">{section.content}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section
          className="max-w-4xl mx-auto prose prose-invert"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <div className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-xl backdrop-filter backdrop-blur-lg">
            <h3 className="text-2xl font-bold mb-4 text-eduvibe-green">Detailed Terms</h3>
            
            <h4 className="text-xl font-bold mb-2 text-eduvibe-green">1. Account Terms</h4>
            <ul className="list-disc pl-6 mb-4">
              <li>You must be 13 years or older to use this service</li>
              <li>You must provide accurate and complete registration information</li>
              <li>You are responsible for maintaining account security</li>
              <li>One person or legal entity may not maintain more than one free account</li>
            </ul>

            <h4 className="text-xl font-bold mb-2 text-eduvibe-green">2. Payment Terms</h4>
            <ul className="list-disc pl-6 mb-4">
              <li>Free trial periods are available to new users only</li>
              <li>Subscription fees are billed in advance on a monthly basis</li>
              <li>All fees are exclusive of taxes</li>
              <li>No refunds for partial months of service</li>
            </ul>

            <h4 className="text-xl font-bold mb-2 text-eduvibe-green">3. Cancellation and Termination</h4>
            <ul className="list-disc pl-6 mb-4">
              <li>You can cancel your account at any time</li>
              <li>We reserve the right to suspend or terminate accounts</li>
              <li>All cancellations will be effective at the end of the current billing period</li>
              <li>You remain responsible for all fees incurred up to the cancellation date</li>
            </ul>

            <p className="text-sm mt-8">
              For questions about these terms, please contact us at legal@eduvibz.com
              <br />
              Last updated: November 2024
            </p>
          </div>
        </motion.section>
      </div>
    </Layout>
  );
};

export default TermsOfService;