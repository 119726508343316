import React from 'react';
import { motion, Variants } from 'framer-motion';
import { Cookie, Lock, Settings, Info } from 'lucide-react';
import Layout from './components/common/Layout';
import PageHeader from './components/common/PageHeader';

const fadeIn: Variants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut",
    },
  },
};

const CookiePolicy: React.FC = () => {
  const sections = [
    {
      icon: <Cookie className="w-12 h-12 text-eduvibe-green" />,
      title: "Essential Cookies",
      content: "These cookies are necessary for the website to function and cannot be switched off in our systems."
    },
    {
      icon: <Lock className="w-12 h-12 text-eduvibe-green" />,
      title: "Analytics Cookies",
      content: "Help us understand how visitors interact with our website by collecting and reporting information anonymously."
    },
    {
      icon: <Settings className="w-12 h-12 text-eduvibe-green" />,
      title: "Functional Cookies",
      content: "Enable enhanced functionality and personalization, such as remembering your preferences."
    },
    {
      icon: <Info className="w-12 h-12 text-eduvibe-green" />,
      title: "Your Control",
      content: "You can control cookie settings through your browser preferences at any time."
    }
  ];

  return (
    <Layout showNorthernLights={true}>
      <PageHeader />
      <div className="container mx-auto px-4 py-8">
        <motion.section
          className="max-w-4xl mx-auto mb-16 text-center"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6 text-eduvibe-green">Cookie Policy</h2>
          <p className="text-xl leading-relaxed mb-8">
            We use cookies to enhance your experience and improve our services. This policy explains how
            we use cookies and your choices regarding them.
          </p>
        </motion.section>

        <motion.section
          className="max-w-6xl mx-auto mb-16"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {sections.map((section, index) => (
              <motion.div
                key={section.title}
                className="bg-eduvibe-blue bg-opacity-50 p-6 rounded-xl backdrop-filter backdrop-blur-lg transform hover:scale-105 transition duration-300"
                initial="hidden"
                animate="visible"
                variants={fadeIn}
                transition={{ delay: index * 0.1 }}
                role="article"
              >
                <div className="flex items-center mb-4">
                  <div aria-hidden="true">{section.icon}</div>
                  <h3 className="text-2xl font-bold ml-4">{section.title}</h3>
                </div>
                <p className="text-white">{section.content}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section
          className="max-w-4xl mx-auto prose prose-invert"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <div className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-xl backdrop-filter backdrop-blur-lg">
            <h3 className="text-2xl font-bold mb-4 text-eduvibe-green">Detailed Cookie Information</h3>
            
            <h4 className="text-xl font-bold mb-2 text-eduvibe-green">1. What Are Cookies?</h4>
            <p className="mb-4">
              Cookies are small text files that are placed on your device when you visit our website. 
              They help us provide you with a better experience by remembering your preferences and 
              understanding how you use our site.
            </p>

            <h4 className="text-xl font-bold mb-2 text-eduvibe-green">2. How We Use Cookies</h4>
            <ul className="list-disc pl-6 mb-4">
              <li>To remember your preferences and settings</li>
              <li>To improve website performance and speed</li>
              <li>To analyze how our website is used</li>
              <li>To personalize your experience</li>
            </ul>

            <h4 className="text-xl font-bold mb-2 text-eduvibe-green">3. Managing Cookies</h4>
            <p className="mb-4">
              Most web browsers allow you to control cookies through their settings preferences. 
              However, limiting cookies may affect your experience of our website.
            </p>

            <p className="text-sm mt-8">
              Last updated: November 2024
            </p>
          </div>
        </motion.section>
      </div>
    </Layout>
  );
};

export default CookiePolicy;