// Replace with your actual GA4 Measurement ID
export const GA_TRACKING_ID = 'G-TVCG63M3Z1';

interface GTagEvent {
  event_category: string;
  event_label?: string;
  value?: number;
  [key: string]: any;
}

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

// Check if analytics are allowed
const isAnalyticsAllowed = (): boolean => {
  return localStorage.getItem('cookieConsent') === 'accepted';
};

export const initGA = (): void => {
  if (!isAnalyticsAllowed()) {
    console.log('Analytics disabled: User has not accepted cookies');
    return;
  }

  // Don't initialize twice
  if ((window as any).gtag) return;

  // Add gtag script
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
  
  // Initialize dataLayer
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).gtag = function() {
    (window as any).dataLayer.push(arguments);
  };
  
  // Basic gtag setup
  (window as any).gtag('js', new Date());
  (window as any).gtag('config', GA_TRACKING_ID, {
    send_page_view: true,
    debug_mode: true
  });

  // Add script to head
  document.head.appendChild(script);

  // Log initialization
  console.log('Google Analytics initialized');
};

const gtag = (...args: any[]): void => {
  if (!(window as any).gtag) {
    console.warn('Google Analytics not initialized');
    return;
  }
  (window as any).gtag(...args);
  console.log('GA Event:', ...args); // Debug logging
};

export const logPageView = (path: string): void => {
  if (!isAnalyticsAllowed()) return;
  gtag('event', 'page_view', {
    page_path: path,
    page_location: window.location.href,
    page_title: document.title
  });
};

export const logEvent = (action: string, category: string, label?: string, value?: number): void => {
  if (!isAnalyticsAllowed()) return;
  const eventParams: GTagEvent = {
    event_category: category,
    ...(label && { event_label: label }),
    ...(value && { value })
  };
  gtag('event', action, eventParams);
};

// Navigation tracking
export const logNavigation = (linkName: string): void => {
  if (!isAnalyticsAllowed()) return;
  logEvent('navigation_click', 'navigation', linkName);
};

// Video interaction tracking
export const logVideoInteraction = (action: 'play' | 'pause' | 'mute' | 'unmute', videoName: string): void => {
  if (!isAnalyticsAllowed()) return;
  logEvent(`video_${action}`, 'video', videoName);
};

// Feature card interaction tracking
export const logFeatureCardInteraction = (cardName: string, action: 'hover' | 'click'): void => {
  if (!isAnalyticsAllowed()) return;
  logEvent(`feature_${action}`, 'feature_card', cardName);
};

// Scroll depth tracking
export const logScrollDepth = (depth: number): void => {
  if (!isAnalyticsAllowed()) return;
  logEvent('scroll_depth', 'page_interaction', `${depth}%`, depth);
};

// Time on page tracking
export const logTimeOnPage = (seconds: number): void => {
  if (!isAnalyticsAllowed()) return;
  logEvent('time_spent', 'engagement', 'duration', seconds);
};

// Button interactions
export const logButtonClick = (buttonName: string): void => {
  if (!isAnalyticsAllowed()) return;
  logEvent('button_click', 'button', buttonName);
};

// Form interactions
export const logFormSubmission = (formName: string): void => {
  if (!isAnalyticsAllowed()) return;
  logEvent('form_submit', 'form', formName);
};

export const logFormInteraction = (formName: string, fieldName: string, action: 'focus' | 'blur' | 'input'): void => {
  if (!isAnalyticsAllowed()) return;
  logEvent(`form_${action}`, 'form_interaction', `${formName}_${fieldName}`);
};

// External link tracking
export const logOutboundLink = (url: string): void => {
  if (!isAnalyticsAllowed()) return;
  logEvent('outbound_click', 'outbound', url);
};

// Error tracking
export const logError = (errorType: string, errorMessage: string): void => {
  if (!isAnalyticsAllowed()) return;
  logEvent('error', 'system', `${errorType}: ${errorMessage}`);
};
