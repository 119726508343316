import React, { useRef, useEffect } from 'react';

const NorthernLights: React.FC = () => {
  const svgRef = useRef<SVGSVGElement>(null);
  const starsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!svgRef.current || !starsRef.current) return;

    const svg = svgRef.current;
    const width = window.innerWidth;
    const height = window.innerHeight;

    svg.setAttribute('viewBox', `0 0 ${width} ${height}`);

    // Create SVG filter for glow effect
    const filter = document.createElementNS('http://www.w3.org/2000/svg', 'filter');
    filter.setAttribute('id', 'glow');
    filter.innerHTML = `
      <feGaussianBlur stdDeviation="3.5" result="coloredBlur"/>
      <feMerge>
        <feMergeNode in="coloredBlur"/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    `;
    svg.appendChild(filter);

    const createWave = (color1: string, color2: string, speed: number, opacity: number, amplitude: number, frequency: number) => {
      const wave = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      const gradient = document.createElementNS('http://www.w3.org/2000/svg', 'linearGradient');
      const gradientId = `gradient-${Math.random().toString(36).substr(2, 9)}`;

      gradient.setAttribute('id', gradientId);
      gradient.innerHTML = `
        <stop offset="0%" stop-color="${color1}"/>
        <stop offset="100%" stop-color="${color2}"/>
      `;
      svg.appendChild(gradient);

      wave.setAttribute('stroke', `url(#${gradientId})`);
      wave.setAttribute('stroke-width', '3');
      wave.setAttribute('opacity', opacity.toString());
      wave.setAttribute('filter', 'url(#glow)');
      wave.setAttribute('fill', 'none');

      return { wave, speed, amplitude, frequency };
    };

    const waves = [
      createWave('#2feA9B', '#52D4F2', 0.001, 0.5, 50, 0.002),
      createWave('#52D4F2', '#2feA9B', 0.002, 0.3, 30, 0.003),
      createWave('#2feA9B', '#52D4F2', 0.001, 0.2, 40, 0.001)
    ];

    waves.forEach(({ wave }) => svg.appendChild(wave));

    // Create stars
    const stars = starsRef.current;
    const numberOfStars = 200;

    for (let i = 0; i < numberOfStars; i++) {
      const star = document.createElement('div');
      star.className = 'star';
      star.style.top = `${Math.random() * 100}%`;
      star.style.left = `${Math.random() * 100}%`;
      star.style.width = `${Math.random() * 2 + 1}px`;
      star.style.height = star.style.width;
      star.style.animationDelay = `${Math.random() * 5}s`;
      stars.appendChild(star);
    }

    let phase = 0;

    const animate = () => {
      waves.forEach(({ wave, speed, amplitude, frequency }) => {
        const points = [];
        for (let x = 0; x <= width + 10; x += 5) {
          let y = Math.sin(x * frequency + phase) * amplitude;
          y += height / 2;
          points.push(`${x},${y}`);
        }
        wave.setAttribute('d', `M${points.join(' ')}`);
      });

      phase += 0.01;
      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      while (svg.firstChild) {
        svg.removeChild(svg.firstChild);
      }
      while (stars.firstChild) {
        stars.removeChild(stars.firstChild);
      }
    };
  }, []);

  return (
    <div className="northern-lights">
      <div ref={starsRef} className="stars"></div>
      <div className="wave-container">
        <svg ref={svgRef} className="w-full h-full"></svg>
      </div>
    </div>
  );
};

export default NorthernLights;
