import React, { useState, useEffect } from 'react';

interface CookieConsentProps {
  onAccept: () => void;
  onDecline: () => void;
}

const CookieConsent: React.FC<CookieConsentProps> = ({ onAccept, onDecline }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if user has already made a choice
    const hasConsent = localStorage.getItem('cookieConsent');
    if (!hasConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setIsVisible(false);
    onAccept();
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setIsVisible(false);
    onDecline();
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-eduvibe-dark text-white shadow-xl z-50 border-t border-eduvibe-green/20">
      <div className="max-w-7xl mx-auto px-3 py-3">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-2 md:gap-4 text-sm">
          {/* Content */}
          <div className="flex-1">
            <p className="leading-tight">
              We use cookies to enhance your experience.{' '}
              <a
                href="/cookie-policy"
                className="text-eduvibe-green hover:text-eduvibe-green/80 underline transition-colors duration-200"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = '/cookie-policy';
                }}
              >
                Learn more
              </a>
            </p>
          </div>

          {/* Buttons */}
          <div className="flex items-center gap-2 w-full md:w-auto">
            <button
              onClick={handleDecline}
              className="flex-1 md:flex-none px-3 py-1.5 text-sm font-medium text-white hover:text-white/90 transition-colors duration-200 rounded border border-gray-600 hover:border-gray-500 hover:bg-gray-800"
            >
              Decline
            </button>
            <button
              onClick={handleAccept}
              className="flex-1 md:flex-none px-4 py-1.5 text-sm font-medium bg-eduvibe-green hover:bg-eduvibe-green/90 text-black rounded transition-colors duration-200"
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
