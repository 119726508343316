import React from 'react';
import { HelmetProvider as ReactHelmetProvider } from 'react-helmet-async';

export const helmetContext = {};

const HelmetProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <ReactHelmetProvider context={helmetContext}>
      {children}
    </ReactHelmetProvider>
  );
};

export default HelmetProvider;
