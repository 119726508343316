import React from 'react';
import { motion, Variants } from 'framer-motion';
import { Music, Brain, Zap, ChevronRight, Book, Headphones, BarChart, Star } from 'lucide-react';
import { GOOGLE_FORM_URL } from './api/waitlist';
import Layout from './components/common/Layout';
import PageHeader from './components/common/PageHeader';

interface Step {
  icon: JSX.Element;
  title: string;
  description: string;
}

const fadeIn: Variants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut",
    },
  },
};

const HowItWorks: React.FC = () => {
  const steps: Step[] = [
    { icon: <Book className="w-12 h-12 text-eduvibe-green" />, title: "Curriculum Integration", description: "We analyze your curriculum and identify key concepts." },
    { icon: <Music className="w-12 h-12 text-eduvibe-green" />, title: "Musical Transformation", description: "Our AI transforms these concepts into catchy, educational songs." },
    { icon: <Brain className="w-12 h-12 text-eduvibe-green" />, title: "Memory Optimization", description: "Songs are crafted to maximize retention and understanding." },
    { icon: <Star className="w-12 h-12 text-eduvibe-green" />, title: "Personalization", description: "Content adapts to your learning style and progress." },
    { icon: <BarChart className="w-12 h-12 text-eduvibe-green" />, title: "Progress Tracking", description: "Monitor your improvement with detailed analytics." },
    { icon: <Headphones className="w-12 h-12 text-eduvibe-green" />, title: "Anytime Learning", description: "Access your musical lessons whenever, wherever." }
  ];

  return (
    <Layout showNorthernLights={true}>
      <PageHeader />
      <div className="container mx-auto px-4 py-8">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center text-eduvibe-green mb-16"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          How EduVibz Works
        </motion.h2>
        
        <motion.section 
          className="max-w-4xl mx-auto mb-16"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <div className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-xl backdrop-filter backdrop-blur-lg">
            <h2 className="text-3xl font-bold text-eduvibe-green mb-6 flex items-center">
              <Brain className="mr-3 w-8 h-8 text-eduvibe-green" />
              The Science Behind EduVibz
            </h2>
            <p className="text-xl mb-4">
              EduVibz is built on a solid foundation of scientific research that demonstrates the powerful connection between music and learning:
            </p>
            <ul className="list-disc list-inside text-lg space-y-4 mb-4">
              <li>
                <strong>Enhanced Memory:</strong> A study by Ludke, Ferreira, & Overy (2014) found that singing can facilitate foreign language learning, with participants showing improved memory for phrases learned through song compared to speech.
              </li>
              <li>
                <strong>Improved Information Processing:</strong> According to Ferreri & Verga (2016), music engages multiple brain areas simultaneously, potentially leading to more efficient information processing and memory formation.
              </li>
              <li>
                <strong>Increased Motivation:</strong> Research by Kang & Williamson (2014) suggests that music can enhance motivation and engagement in learning tasks, particularly for language acquisition.
              </li>
            </ul>
            <p className="text-lg italic">
              These findings form the backbone of our innovative approach to learning at EduVibz.
            </p>
          </div>
        </motion.section>

        <motion.section 
          className="max-w-6xl mx-auto mb-16"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h2 className="text-3xl font-bold mb-8 text-center text-eduvibe-green">
            The EduVibz Process
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {steps.map((step, index) => (
              <motion.div
                key={step.title}
                className="bg-eduvibe-blue bg-opacity-50 p-6 rounded-xl backdrop-filter backdrop-blur-lg transform hover:scale-105 transition duration-300"
                initial="hidden"
                animate="visible"
                variants={fadeIn}
                transition={{ delay: index * 0.1 }}
              >
                <div className="flex items-center mb-4">
                  <div aria-hidden="true">{step.icon}</div>
                  <h3 className="text-2xl font-bold ml-4">{step.title}</h3>
                </div>
                <p className="text-white">{step.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section 
          className="max-w-4xl mx-auto mb-16"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <div className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-xl backdrop-filter backdrop-blur-lg">
            <h2 className="text-3xl font-bold text-eduvibe-green mb-6">Key Features and Benefits</h2>
            <ul className="space-y-4 text-lg">
              <li className="flex items-start">
                <Music className="mr-4 w-8 h-8 text-eduvibe-green flex-shrink-0" />
                <span><strong>Customized Learning Tracks:</strong> Our AI creates personalized educational songs tailored to your curriculum and learning objectives.</span>
              </li>
              <li className="flex items-start">
                <Brain className="mr-4 w-8 h-8 text-eduvibe-green flex-shrink-0" />
                <span><strong>Multi-Sensory Learning:</strong> Engage auditory, visual, and kinesthetic learning styles simultaneously for improved retention.</span>
              </li>
              <li className="flex items-start">
                <Zap className="mr-4 w-8 h-8 text-eduvibe-green flex-shrink-0" />
                <span><strong>Adaptive Learning:</strong> Our platform adjusts to each student's progress, ensuring optimal challenge and growth.</span>
              </li>
              <li className="flex items-start">
                <BarChart className="mr-4 w-8 h-8 text-eduvibe-green flex-shrink-0" />
                <span><strong>Progress Tracking:</strong> Detailed analytics help students, parents, and educators monitor improvement over time.</span>
              </li>
            </ul>
          </div>
        </motion.section>

        <motion.section 
          className="max-w-4xl mx-auto mb-16"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <div className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-xl backdrop-filter backdrop-blur-lg">
            <h2 className="text-3xl font-bold text-eduvibe-green mb-6 flex items-center">
              <Book className="mr-3 w-8 h-8 text-eduvibe-green" />
              Backed by Research
            </h2>
            <p className="text-xl mb-4">
              EduVibz's methodology is grounded in peer-reviewed research:
            </p>
            <ul className="list-disc list-inside text-lg space-y-4">
              <li>Ludke, K. M., Ferreira, F., & Overy, K. (2014). Singing can facilitate foreign language learning. Memory & cognition, 42(1), 41-52.</li>
              <li>Ferreri, L., & Verga, L. (2016). Benefits of music on verbal learning and memory: How and when does it work?. Music Perception: An Interdisciplinary Journal, 34(2), 167-182.</li>
              <li>Kang, H. J., & Williamson, V. J. (2014). Background music can aid second language learning. Psychology of Music, 42(5), 728-747.</li>
              <li>Altenmüller, E., & Schlaug, G. (2013). Neurobiological aspects of neurologic music therapy. Music and Medicine, 5(4), 210-216.</li>
            </ul>
          </div>
        </motion.section>

        <motion.section 
          className="text-center mt-16"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h2 className="text-3xl font-bold mb-6">Ready to Transform Your Learning?</h2>
          <a 
            href={GOOGLE_FORM_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-gradient-to-r from-eduvibe-green to-eduvibe-blue text-white px-8 py-4 rounded-lg text-xl font-bold hover:opacity-90 transition duration-300 inline-flex items-center"
          >
            Join Waitlist
            <ChevronRight className="ml-2" />
          </a>
        </motion.section>
      </div>
    </Layout>
  );
};

export default HowItWorks;