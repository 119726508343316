import React from 'react';
import { motion, Variants } from 'framer-motion';
import { Music, Brain, Lightbulb, Heart, Star, Users, Award } from 'lucide-react';
import { GOOGLE_FORM_URL } from './api/waitlist';
import Layout from './components/common/Layout';
import PageHeader from './components/common/PageHeader';

interface JourneyStep {
  icon: JSX.Element;
  title: string;
  description: string;
}

interface ValueCard {
  icon: JSX.Element;
  title: string;
  description: string;
}

const fadeIn: Variants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut",
    },
  },
};

const AboutUs: React.FC = () => {
  const journey: JourneyStep[] = [
    { icon: <Lightbulb className="w-12 h-12 text-eduvibe-green" />, title: "The Spark", description: "A childhood passion for entrepreneurship and innovation led to the birth of EduVibz" },
    { icon: <Brain className="w-12 h-12 text-eduvibe-green" />, title: "The Research", description: "Deep dive into music's impact on learning and memory retention" },
    { icon: <Music className="w-12 h-12 text-eduvibe-green" />, title: "The Innovation", description: "Combining educational content with engaging musical elements" }
  ];

  const values: ValueCard[] = [
    { icon: <Heart className="w-12 h-12 text-eduvibe-green" />, title: "Passion", description: "We're passionate about transforming education through the power of music" },
    { icon: <Star className="w-12 h-12 text-eduvibe-green" />, title: "Excellence", description: "Committed to delivering the highest quality educational content" },
    { icon: <Users className="w-12 h-12 text-eduvibe-green" />, title: "Community", description: "Building a supportive community of learners and educators" },
    { icon: <Award className="w-12 h-12 text-eduvibe-green" />, title: "Innovation", description: "Continuously evolving our platform with cutting-edge technology" }
  ];

  return (
    <Layout showNorthernLights={true}>
      <PageHeader />
      <div className="container mx-auto px-4 py-8">
        <motion.section 
          className="max-w-4xl mx-auto mb-16 text-center"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6 text-eduvibe-green">Our Mission</h2>
          <p className="text-xl leading-relaxed mb-8 bg-eduvibe-blue bg-opacity-50 p-6 rounded-xl backdrop-filter backdrop-blur-lg">
            "To revolutionize education by harnessing the power of music, making learning more engaging, 
            memorable, and enjoyable for students worldwide."
          </p>
        </motion.section>

        <motion.section 
          className="max-w-6xl mx-auto mb-16"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h2 className="text-4xl font-bold mb-8 text-center text-eduvibe-green">Our Journey</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {journey.map((step, index) => (
              <motion.div
                key={step.title}
                className="bg-eduvibe-blue bg-opacity-50 p-6 rounded-xl backdrop-filter backdrop-blur-lg text-center transform hover:scale-105 transition duration-300"
                initial="hidden"
                animate="visible"
                variants={fadeIn}
                transition={{ delay: index * 0.1 }}
                role="article"
              >
                <div aria-hidden="true">{step.icon}</div>
                <h3 className="text-2xl font-bold mb-2">{step.title}</h3>
                <p className="text-white">{step.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section 
          className="max-w-6xl mx-auto mb-16"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h2 className="text-4xl font-bold mb-8 text-center text-eduvibe-green">Our Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {values.map((value, index) => (
              <motion.div
                key={value.title}
                className="bg-eduvibe-blue bg-opacity-50 p-6 rounded-xl backdrop-filter backdrop-blur-lg text-center transform hover:scale-105 transition duration-300"
                initial="hidden"
                animate="visible"
                variants={fadeIn}
                transition={{ delay: index * 0.1 }}
                role="article"
              >
                <div aria-hidden="true">{value.icon}</div>
                <h3 className="text-2xl font-bold mb-2">{value.title}</h3>
                <p className="text-white">{value.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section
          className="max-w-4xl mx-auto text-center"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h2 className="text-4xl font-bold mb-8 text-eduvibe-green">Join Our Journey</h2>
          <p className="text-xl mb-8">
            Be part of the education revolution. Join our waitlist and experience the future of learning.
          </p>
          <a
            href={GOOGLE_FORM_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-gradient-to-r from-eduvibe-green to-eduvibe-blue text-white px-8 py-4 rounded-lg text-xl font-bold hover:opacity-90 transition duration-300"
          >
            Join Waitlist
          </a>
        </motion.section>
      </div>
    </Layout>
  );
};

export default AboutUs;